<template>
  <div>
    <a-modal
      :visible="visible.modalAddJabatan"
      title="Buat Jabatan Baru"
      okText="Buat"
      @ok="handleCreateJabatan"
      @cancel="handleCancel"
      :okButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
    >
      <div
        v-if="loading.add"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
          />
        </a-spin>
      </div>
      <a-form
        v-else
        layout="horizontal"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 15 }"
      >
        <a-form-item label="Nama">
          <a-input
            :value="this.jabatanName"
            :placeholder="
              'Nama Jabatan'
            "
            @change="(e) => handleAddJabatanChange(e.target.value, 'nama')"
          />
        </a-form-item>
        <a-form-item label="Unit">
          <a-select
            style="width: 100%; height: 40px"
            @change="handleSelectUnit"
            placeholder="Pilih Unit"
          >
            <a-select-option
              v-for="item in listUnit"
              :key="item.id"
              :value="item.id"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :visible="visible.modalEditJabatan"
      :confirm-loading="loading.confirm"
      @ok="handleOkEditJabatan"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Perbaharui"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <h3 slot="title" style="color: #faad14">
        <a-icon style="font-size: 1.2em" type="warning" /> KONFIRMASI EDIT
        JABATAN
      </h3>
      <p>
        Apakah anda yakin mau memperbarui nama jabatan ini? Setelah diperbarui,
        data lama jabatan ini akan digantikan dengan data yang baru.
      </p>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="mr-3">
        <a-input
          v-model="jabatanSearch"
          @keyup="searchJabatan"
          size="large"
          style="width: 320px"
          :placeholder="`Cari Nama Jabatan...`"
        />
      </div>
      <div class="ml-auto">
        <template v-if="!edit.jabatan">
          <a-button
            class="mr-3"
            size="large"
            type="primary"
            @click.prevent="showModalAddJabatan(true)"
            icon="plus"
            >Tambah</a-button
          >
          <a-button size="large" @click.prevent="toEditJabatan" icon="edit"
            >Ubah</a-button
          >
        </template>
        <template v-else>
          <a-button
            @click.prevent="cancelEdit"
            :loading="loading.cancel"
            type="danger"
            size="large"
            icon="close"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >Batal</a-button
          >
          <a-button
            size="large"
            @click.prevent="saveEdit"
            icon="save"
            style="background-color: #41b883; color: white"
            >Simpan</a-button
          >
        </template>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        v-if="edit.jabatan"
        :columns="jabatanColumns"
        :data-source="jabatanDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeJabatan"
        bordered
      >
        <div slot="nama" slot-scope="text, record">
          <a-input
            :value="text"
            style="height: 50px; width: 100%"
            @change="
              (e) => handleEditJabatanChange(e.target.value, record.key, 'nama')
            "
          />
        </div>
        <div slot="unit" slot-scope="text, record">
          <a-select
            :default-value="text"
            style="width: 100%; height: 40px"
            @change="(e) => handleEditJabatanChange(e, record.key, 'id_unit')"
            placeholder="Pilih Unit"
          >
            <a-select-option
              v-for="item in listUnit"
              :key="item.id"
              :value="item.id"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </div>
        <div slot="aksi" slot-scope="text, record">
          <a-button
            size="large"
            class="text-danger border border-danger"
            :value="text"
            :loading="loading.delete"
            @click.prevent="(e) => deleteJabatan(e.target.value, record.key)"
            ><a-icon type="delete" />Hapus</a-button
          >
        </div>
      </a-table>
      <a-table
        v-else
        :columns="jabatanColumns"
        :data-source="jabatanDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :scroll="{ x: 1150 }"
        @change="handleTableChangeJabatan"
        bordered
      >
        <div slot="jabatan" slot-scope="text">
          {{ thousandPrice(text) }}
        </div>
        <div slot="fungsional" slot-scope="text">
          {{ thousandPrice(text) }}
        </div>
        <div slot="operasional" slot-scope="text">
          {{ thousandPrice(text) }}
        </div>
        <div slot="makan" slot-scope="text">
          {{ thousandPrice(text) }}
        </div>
        <div slot="transportasi" slot-scope="text">
          {{ thousandPrice(text) }}
        </div>
        <div slot="lainLain" slot-scope="text">
          {{ thousandPrice(text) }}
        </div>
        <div slot="aksi" slot-scope="text, record">
          <a-button
            :disabled="edit.jabatan"
            size="large"
            class="text-danger border border-danger"
            :value="text"
            :loading="loading.delete"
            @click.prevent="(e) => deleteJabatan(e.target.value, record.key)"
            ><a-icon type="delete" />Hapus</a-button
          >
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import thousandPrice from '@/helpers/thousandPrice'

const jabatanColumns = [
  {
    title: 'Nama Jabatan',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    fixed: 'left',
    width: 200,
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    scopedSlots: { customRender: 'unit' },
    fixed: 'left',
    width: 150,
    align: 'center',
  },
  {
    title: 'Tunjangan',
    dataIndex: 'tunjangan',
    key: 'tunjangan',
    scopedSlots: { customRender: 'tunjangan' },
    align: 'center',
    children: [
      {
        title: 'Jabatan (%)',
        dataIndex: 'jabatan',
        key: 'jabatan',
        scopedSlots: { customRender: 'jabatan' },
        align: 'right',
        width: 110,
      },
      {
        title: 'Fungsional (%)',
        dataIndex: 'fungsional',
        key: 'fungsional',
        scopedSlots: { customRender: 'fungsional' },
        align: 'right',
        width: 117,
      },
      {
        title: 'Operasional (Rp)',
        dataIndex: 'operasional',
        key: 'operasional',
        scopedSlots: { customRender: 'operasional' },
        align: 'right',
        width: 120,
      },
      {
        title: 'Makan (Rp)',
        dataIndex: 'makan',
        key: 'makan',
        scopedSlots: { customRender: 'makan' },
        align: 'right',
        width: 110,
      },
      {
        title: 'Transportasi (Rp)',
        dataIndex: 'transportasi',
        key: 'transportasi',
        scopedSlots: { customRender: 'transportasi' },
        align: 'right',
        width: 117,
      },
      {
        title: 'Lain-Lain (Rp)',
        dataIndex: 'lainLain',
        key: 'lainLain',
        scopedSlots: { customRender: 'lainLain' },
        align: 'right',
        width: 117,
      },
    ],
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
    fixed: 'right',
  },
]

export default {
  name: 'master-jabatan',
  data() {
    return {
      thousandPrice,
      jabatanColumns,
      jabatanDataTable: [],
      savedJabatanData: [],
      visible: {
        modalAddJabatan: false,
        modalEditJabatan: false,
      },
      loading: {
        table: false,
        add: false,
        delete: false,
        cancel: false,
        confirm: false,
      },
      pagination: {},
      jabatanPage: 'all',
      jabatanSearch: '',
      jabatanOrder: '',
      jabatanSortBy: '',
      jabatanName: null,
      edit: {
        jabatan: false,
      },
      listUnit: [],
      filterUnit: [],
      selectUnit: null,
    }
  },
  methods: {
    handleEditJabatanChange(value, key, column) {
      // console.log('value, key, column :>> ', value, key, column)
      // console.log('this.jabatanDataTable :>> ', this.jabatanDataTable)
      const newData = [...this.jabatanDataTable]
      const target = newData.filter((item) => key === item.key)[0]
      // console.log('target :>> ', target)
      if (target) {
        target[column] = value
        this.savedJabatanData = newData
      }
    },
    handleTableChangeJabatan(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'nama') {
        sortBy = 'nama'
      } else if (sorter.field === 'unit') {
        sortBy = 'nama'
      } else {
        sortBy = ''
      }
      this.fetchDataJabatan({
        page: pagination.current,
        order,
        sortBy,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.add = boolean
      this.loading.delete = boolean
      this.loading.cancel = boolean
    },
    showModalAddJabatan(boolean) {
      this.visible.modalAddJabatan = boolean
    },
    searchJabatan() {
      if (this.jabatanSearch.length > 2) {
        this.fetchDataJabatan({
          search: this.jabatanSearch,
        })
      } else {
        this.fetchDataJabatan({
          search: this.jabatanSearch,
        })
      }
    },
    fetchDataJabatan(
      params = {
        order: this.jabatanOrder,
        sortBy: this.jabatanSortBy,
        search: this.jabatanSearch,
        page: this.jabatanPage,
      },
    ) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminHR/GET_JABATAN_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          this.listUnit = res.listUnit
          this.jabatanDataTable = res.data.map((row) => {
            const jabatan =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_jabatan
            const fungsional =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_fungsional
            const operasional =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_operasional
            const makan =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_makan
            const transportasi =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_transportasi
            const lainLain =
              row.jabatan_tunjangan == null
                ? ''
                : row.jabatan_tunjangan.tunjangan_lain
            return {
              key: row.id,
              id_unit: row.id_unit,
              nama: row.nama,
              unit: row.unit.nama,
              jabatan,
              fungsional,
              operasional: operasional,
              makan: makan,
              transportasi: transportasi,
              lainLain: lainLain,
            }
          })
          this.savedJabatanData = this.jabatanDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalAddJabatan = false
      this.visible.modalEditJabatan = false
    },
    handleAddJabatanChange(value, column) {
      if (column === 'nama') {
        this.jabatanName = value
      }
    },
    handleCreateJabatan(value) {
      if ((this.jabatanName === '' || this.jabatanName === null) || (this.selectUnit === null || this.selectUnit === '')) {
        this.$notification.error({
          message: 'Error',
          description: 'Harap mengisi semua data jabatan! Tidak boleh ada data yang kosong!',
        })
        this.jabatanName = null
        this.selectUnit = null
        this.visible.modalAddJabatan = false
      } else {
        this.handleAllLoading(true)
        this.$store
          .dispatch('adminHR/CREATE_JABATAN', {
            data: {
              nama: this.jabatanName,
              id_unit: this.selectUnit,
            },
          })
          .then((status) => {
            this.fetchDataJabatan()
            this.visible.modalAddJabatan = false
            if (status === 200) {
              setTimeout(() => {
                this.$notification.success({
                  message: 'Sukses',
                  description: `Berhasil menambah jabatan baru ${this.jabatanName}`,
                })
              })
            } else {
              setTimeout(() => {
                this.$notification.error({
                  message: 'Gagal',
                  description: `Gagal menambah jabatan baru ${this.jabatanName}`,
                })
              })
            }
            this.jabatanName = null
            this.selectUnit = null
            this.handleAllLoading(false)
          })
      }
    },
    deleteJabatan(value, key) {
      this.handleAllLoading(true)
      this.$confirm({
        title: 'Peringatan!!',
        content: (
          <div>
            Apakah anda yakin ingin menghapus jabatan ini? Setelah menghapus,
            data jabatan ini akan hilang!
          </div>
        ),
        onOk: () => {
          this.$store
            .dispatch('adminHR/DELETE_JABATAN', {
              id: key,
            })
            .then((res) => {
              this.handleAllLoading(false)
              if (res === 200) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Jabatan berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Jabatan tidak berhasil dihapus',
                })
              }
              this.fetchDataJabatan()
            })
        },
        onCancel: () => {
          this.handleAllLoading(false)
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    toEditJabatan() {
      this.edit.jabatan = true
    },
    saveEdit() {
      this.visible.modalEditJabatan = true
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataJabatan()
      setTimeout(() => {
        this.edit.jabatan = false
        this.handleAllLoading(false)
      })
    },
    validateJabatanDataInput(data) {
      let isInvalid = false
      let message = ''
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].nama === '' ||
          data[i].nama === '-' ||
          data[i].nama === null
        ) {
          isInvalid = true
          message = `Maaf nama, ke-${i + 1} tidak boleh kosong!`
          break
        }
      }
      return {
        isInvalid,
        message,
      }
    },
    handleOkEditJabatan() {
      // console.log('this.savedJabatanData :>> ', this.savedJabatanData)
      const newData = [...this.savedJabatanData]
      if (newData.length) {
        const validation = this.validateJabatanDataInput(newData)
        if (validation.isInvalid) {
          this.$notification.error({
            message: 'Sorry.',
            description: validation.message,
          })
          this.visible.modalEditJabatan = false
        } else {
          const target = newData.map((row) => {
            return {
              id: row.key,
              nama: row.nama,
              id_unit: row.id_unit,
              unit: row.unit,
              jabatan: row.jabatan,
              fungsional: row.fungsional,
              operasional: row.operasional,
              makan: row.makan,
              transportasi: row.transportasi,
              lainLain: row.lainLain,
            }
          })
          // console.log('Berhasil', target)
          this.confirmLoading = true
          this.$store
            .dispatch('adminHR/UPDATE_JABATAN', {
              data: target,
            })
            .then((res) => {
              setTimeout(() => {
                this.fetchDataJabatan()
                this.edit.jabatan = false
                this.visible.modalEditJabatan = false
                this.handleAllLoading(false)
                if (res.isSuccess) {
                  this.$notification.success({
                    message: 'Sukses',
                    description: 'Jabatan berhasil diperbarui',
                  })
                } else {
                  this.$notification.error(
                    {
                      message: 'Gagal',
                      description: 'Jabatan tidak berhasil diperbarui',
                    },
                    1000,
                  )
                }
              })
            })
        }
      } else {
        this.edit.jabatan = false
        this.visible.modalEditJabatan = false
        this.handleAllLoading(false)
        setTimeout(() => {
          this.$notification.error({
            message: 'Gagal',
            description: 'Jabatan tidak berhasil diperbarui',
          })
        })
      }
    },
    handleSelectUnit(value) {
      this.filterUnit = []
      this.selectUnit = value
      const target = this.listUnit.filter((el) => el.id === this.selectUnit)
      if (target) {
        this.filterUnit = target
      }
    },
  },
  mounted() {
    this.fetchDataJabatan()
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black;
}
</style>
